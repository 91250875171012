import SignalRClient from "./services/signalr";
import FleetoraClient from "./services/fleetora";
import CdnClient from "./services/cdn";
import { CachedSnapshot, Carrier, Paints, Vehicle } from "./entities";
import * as Constants from "./constants";

export let carrierLoader: Promise<void>;
export let paintsLoader: Promise<void>;
export let orderingsLoader: Promise<void>;
export let vehiclesLoader: Promise<void>;

export let carrier: Carrier;
export let paints: Paints;
export let vehicles: Vehicle[];
export let cachedSnapshot: CachedSnapshot;

export let cdnClient = new CdnClient();
export let signalRClient = new SignalRClient();
export let fleetoraClient = new FleetoraClient(Constants.fleetoraApiKey);

export async function loadCarrier() {
  carrier = await cdnClient.getCarrier();
}

export async function loadPaints() {
  paints = await cdnClient.getPaints();
}

export async function loadTraffic(preview: Date) {
  if (preview) {
    try {
      cachedSnapshot = await fleetoraClient.getOldTraffic(preview);
      vehicles = cachedSnapshot.vehicles;
    } catch (err) {
      console.error("Failed to request old traffic through Fleetora", err);

      throw err;
    }
  } else {
    try {
      cachedSnapshot = await fleetoraClient.getTraffic();
      vehicles = cachedSnapshot.vehicles;
    } catch (err) {
      console.error("Failed to request through Fleetora, trying SignalR", err);

      try {
        vehicles = await signalRClient.getTraffic();
      } catch (err) {
        console.error("Failed to request through SignalR", err);

        throw err;
      }
    }
  }
}

export async function load(preview: Date) {
  carrierLoader = loadCarrier();
  paintsLoader = loadPaints();
  vehiclesLoader = loadTraffic(preview);

  await Promise.all([carrierLoader, paintsLoader, vehiclesLoader]);
}
