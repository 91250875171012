export const content = document.querySelector("main .content");
export const templates = {
  vehiclesPage: document.querySelector<HTMLTemplateElement>("template[name='vehicles-page']"),
  traction: document.querySelector<HTMLTemplateElement>("template[name='traction']"),
  model: document.querySelector<HTMLTemplateElement>("template[name='model']"),
  vehicleTag: document.querySelector<HTMLTemplateElement>("template[name='vehicle-tag']")
};
export const dialogs = {
  error: {
    self: document.querySelector<HTMLDialogElement>("dialog[name='error']"),
    close: document.querySelector<HTMLDivElement>("dialog[name='error'] .close"),
    message: document.querySelector<HTMLParagraphElement>("dialog[name='error'] .message")
  },
  success: {
    self: document.querySelector<HTMLDialogElement>("dialog[name='success']"),
    close: document.querySelector<HTMLDivElement>("dialog[name='success'] .close"),
    title: document.querySelector<HTMLHeadingElement>("dialog[name='success'] h2"),
    message: document.querySelector<HTMLParagraphElement>("dialog[name='success'] .message")
  },
  vehicleView: {
    self: document.querySelector<HTMLDialogElement>("dialog[name='vehicle-view']"),
    close: document.querySelector<HTMLDivElement>("dialog[name='vehicle-view'] .close"),
    tag: document.querySelector<HTMLSpanElement>("dialog[name='vehicle-view'] .tag"),
    heading: document.querySelector<HTMLHeadingElement>("dialog[name='vehicle-view'] h2"),
    seznamAutobusu: document.querySelector<HTMLAnchorElement>("dialog[name='vehicle-view'] a.seznam-autobusu"),
    provoz: document.querySelector<HTMLAnchorElement>("dialog[name='vehicle-view'] a.provoz"),
    delay: document.querySelector<HTMLLabelElement>("dialog[name='vehicle-view'] .delay label"),
    paintContainer: document.querySelector<HTMLLabelElement>("div.vehicle-metadata.paint "),
    paint: document.querySelector<HTMLLabelElement>("dialog[name='vehicle-view'] .paint label"),
    path: document.querySelector<HTMLLabelElement>("dialog[name='vehicle-view'] .path label"),
    lastStop: document.querySelector<HTMLLabelElement>("dialog[name='vehicle-view'] .last-stop label"),
    nextStop: document.querySelector<HTMLLabelElement>("dialog[name='vehicle-view'] .next-stop label"),
    order: document.querySelector<HTMLLabelElement>("dialog[name='vehicle-view'] .order label"),
    shift: document.querySelector<HTMLLabelElement>("dialog[name='vehicle-view'] .shift label"),
    connectionTableBody: document.querySelector<HTMLLabelElement>("dialog[name='vehicle-view'] .connection tbody")
  },
  notificationsView: {
    self: document.querySelector<HTMLDialogElement>("dialog[name='notifications-view']"),
    close: document.querySelector<HTMLDivElement>("dialog[name='notifications-view'] .close")
  },
  contactView: {
    self: document.querySelector<HTMLDialogElement>("dialog[name='contact-view']"),
    form: {
      self: document.querySelector<HTMLFormElement>("dialog[name='contact-view'] form"),
      email: document.querySelector<HTMLInputElement>("dialog[name='contact-view'] form input[name='email']"),
      contentLabel: document.querySelector<HTMLLabelElement>("dialog[name='contact-view'] form label[for='content']"),
      content: document.querySelector<HTMLTextAreaElement>("dialog[name='contact-view'] form textarea"),
      file: document.querySelector<HTMLInputElement>("dialog[name='contact-view'] form input[name='file']"),
      submit: document.querySelector<HTMLInputElement>("dialog[name='contact-view'] form input[type='submit']")
    },
    close: document.querySelector<HTMLDivElement>("dialog[name='contact-view'] .close")
  },
  faqView: {
    self: document.querySelector<HTMLDialogElement>("dialog[name='faq-view']"),
    close: document.querySelector<HTMLDivElement>("dialog[name='faq-view'] .close")
  }
};
export const vehicleCount = document.querySelector<HTMLSpanElement>(".vehicle-count");
export const status = document.querySelector<HTMLParagraphElement>(".status");
export const disclaimer = document.querySelector<HTMLParagraphElement>(".disclaimer");
